
/* eslint-disable */
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import { VxeFormProps } from "vxe-table";

import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import VueTagsInput from "@sipec/vue3-tags-input";

export default defineComponent({
  components: {
    Grid,
    FileUploader,
    VueTagsInput,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});
    var printColumns = [{ field: "Name" }, { field: "Description" }];
    const gridOptions: GridOptions = {
      id: "user",
      title: "熱門景點",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "熱門景點清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "熱門景點清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true, filters: [{ checked: false, label: "包含" }], slots: { filter: "filter-name" } },
        { field: "Published", title: "是否發布", showHeaderOverflow: true, showOverflow: true, resizable: false, width: 100, cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否', disabled: true } } }
      ],
      promises: {
        query: model ? (params) => model.dispatch("scenery/query", params) : undefined,
        queryAll: model ? () => model.dispatch("scenery/query") : undefined,
        save: model ? (params) => model.dispatch("scenery/save", params) : undefined,
      },
      modalConfig: { width: 600, showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: "Cover.Uri", title: "封面圖", span: 24, slots: { default: "column-cover" } },
        { field: "Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "名稱" } } },
        { field: "Published", title: "是否發布", span: 12, itemRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否' } } },
        { field: "Longitude", title: "經度", span: 12, itemRender: { name: "$input" } },
        { field: "Latitude", title: "緯度", span: 12, itemRender: { name: "$input" } },
        // { field: 'Address.Line', title: '地址', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入地址' } } },
        { field: 'LinkUrl', title: '連結網址', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入連結網址' } } },
        { field: 'YoutubeUrl', title: 'Youtube連結', titleWidth: 100, span: 24, slots: { default: "column-youtube-url" } },
        // { field: "Description", title: "說明", span: 24, itemRender: { name: "$input", props: { placeholder: "說明" } } },
      ],
      rules: {
        Name: [{ required: true }],
        Capacity: [{ required: true }],
      }
    };
    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter(current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    }
  },
});
